let hamburger = document.querySelector(".hamburger");
let navListContainer = document.querySelector(".nav__list-container");
let header = document.getElementById("nav");

hamburger.onclick = () => {
  hamburger.classList.toggle("is-active");
  navListContainer.classList.toggle("nav__list-container--open");
  header.classList.toggle("open");
};

const modal1 = document.querySelector("#modal-1")
const title = modal1.querySelector("header h2")
const langSelect = modal1.querySelector("#modal-lang select")
const enBlock = modal1.querySelector("#en-block")
const esBlock = modal1.querySelector("#es-block")

const setModalSelection = (isEnglish)=>{
  title.innerHTML = isEnglish? "FESTIVE SEASON CELEBRATIONS!" : "¡LlEGO La NaVIdAD!";
  enBlock.style.display = isEnglish? "block":"none";
  esBlock.style.display = isEnglish? "none":"block";
}

langSelect.addEventListener("change", (e)=>setModalSelection(e.target.value==="en"))



